<template>
  <v-select
    v-model="countryId"
    placeholder="Select Country"
    :items="countryOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    clearable
    :hide-details="hideDetails"
    :disabled="disabled"
    :dense="dense"
  >
  </v-select>
</template>
<script>
import { useCountry } from '@/composables'
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { countryOptions, findCountry } = useCountry()

    const countryId = ref(props.filters.country_id)

    const emitFilterChange = country_id => emit('changed', {
      ...props.filters,
      country_id,
    })

    const emitCountryChange = id => emit('country-changed', findCountry(id))

    watch(countryId, id => {
      emitFilterChange(id)
      emitCountryChange(id)
    })

    return {
      countryOptions,
      countryId,
    }
  },
}
</script>
